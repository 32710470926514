import React, {Component} from 'react';
import ContestLogo from '../../CampaignLogo/CampaignLogo';
import NavigationItem from '../NavigationItem/NavigationItem';
import CompetitionLogoImage from '../../../assets/images/logo-brand-competition.svg';
import MenuItemPlaceholder from "../../UI/Placeholder/MenuItemPlaceholder/MenuItemPlaceholder";
import classes from './MainMenu.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/free-solid-svg-icons'

import {Button, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavLink} from 'reactstrap';
import {NavLink as RRNavLink} from 'react-router-dom';

class MainMenu extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        let primaryNavigation = (
            <React.Fragment>
                <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
                <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
                <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
                <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            </React.Fragment>
        );
        let primaryNavigationCTA = '';
        if (this.props.primaryNavigation) {
            primaryNavigation = this.props.primaryNavigation.map((menuItem, index) => {
                return <NavigationItem key={index} type="header">{
                    /https?:\/\/.*/.test(menuItem.linkTarget)
                        ? <a className={classes.NavLink + ` anl_main-menu--lnk-${index + 1} nav-link`}
                             href={menuItem.linkTarget}
                             target="_blank">
                            {menuItem.text} <FontAwesomeIcon icon={faExternalLink}/>
                        </a>
                        : <NavLink className={classes.NavLink + ` anl_main-menu--lnk-${index + 1}`}
                                   to={menuItem.linkTarget === '/' ? '/' : menuItem.linkTarget + '/'} tag={RRNavLink}
                                   exact>{menuItem.text}</NavLink>
                }
                </NavigationItem>
            })
            primaryNavigationCTA = this.props.primaryNavigationCTA.map((menuItem, index) => {
                return /https?:\/\/.*/.test(menuItem.linkTarget)
                    ? <a href={menuItem.linkTarget}
                         key={index}
                         target="_blank">
                        <Button color="primary"
                                className={`anl_main-menu--btn-${index + 1}`}>
                            {menuItem.text}&nbsp;
                            <FontAwesomeIcon icon={faExternalLink}/></Button></a>
                    : <Button key={index} color="secondary" to={menuItem.linkTarget} tag={RRNavLink}
                              className={`anl_main-menu--btn-${index + 1}`}>{menuItem.text}</Button>
            })
        }

        return (
            <Navbar className={classes.Navbar} light expand="lg">
                <NavbarBrand className={`${classes.NavbarBrand} anl_logo--contest`} tag={RRNavLink} to="/" exact>
                    <ContestLogo src={CompetitionLogoImage}/>
                </NavbarBrand>

                <NavbarToggler className="anl_mobile--menu" onClick={this.toggle} onBlur={this.toggle}/>

                <Collapse className="justify-content-end" isOpen={this.state.isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        {primaryNavigation}
                    </Nav>
                    {primaryNavigationCTA}
                </Collapse>
            </Navbar>
        );
    }
}

export default MainMenu;
