import React, {Component} from 'react';
import {Col} from 'reactstrap';
import CountUp from "react-countup";
import Cookies from "js-cookie";
import classes from "../Countdown/Countdown.module.scss";

class FundingPoolCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animate: false,
            animateMessage: 'Jetzt mit SMS-Code abstimmen!'
        }
    }

    componentDidUpdate() {
        if (Cookies.get('bigfoot_code-requested') && !this.state.animate) {
            this.animateHandler(true);
        } else if (!Cookies.get('bigfoot_code-requested') && this.state.animate) {
            this.animateHandler(false);
        }
    }

    animateHandler = (animateValue) => {
        this.setState(({
            animate: animateValue
        }));
    }

    render() {
        return (
            <Col
                className={`text-white bg-primary px-0 py-1 text-center ${this.state.animate && classes.CountdownWrapper}`}>
                <p className={`mb-0 ${this.state.animate && classes.animatedElement + " " + classes.CountdownTimer}`}>
                    Es sind noch <CountUp start={0} end={this.props.balance} duration={2} separator="."
                                          delay={2}/> Kamelle im Fördertopf
                </p>
                {this.state.animate &&
                    <p className={`mb-0 py-1 ${classes.animatedElement} ${classes.CountDownMessage}`}>{this.state.animateMessage}</p>}
            </Col>
        );
    }
}

FundingPoolCounter.defaultProps = {
    balance: 0
}

export default FundingPoolCounter;
